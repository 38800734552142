import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../../../components/layout'
import Seo from '../../../components/seo';
import Modal from '../../../components/modal';
import Bootstrap from '../../../utils/bootstrap';
import Origin from '../../../utils/origin';
import Analytics from '../../../utils/analytics';
import axios from 'axios';
import $ from 'jquery';
import './styles/main.scss';
import '../../../../static/styles/elements/recaptcha.scss';

const DividendsMapSuccessPage = (location) => {
  const [loading, setLoading] = useState(false);
  const [origin, setOrigin] = useState();
  
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [phone, setPhone] = useState('');
  let [patrimony, setPatrimony] = useState('');
  let [password, setPassword] = useState('');
  let [passwordError, setPasswordError] = useState('');
  let [showPasswordForm, setShowPasswordForm] = useState(false);
  let [recaptchaError, setRecaptchaError] = useState(false);

  const yearData = (new Date().getFullYear());
  const year = yearData < 2022 ? (yearData+1) : yearData;

  const goToLogin = event => {
    navigate('/conta/login/');
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'password') {
      setPasswordError('');
      setPassword(value);
    }
  };

  const handleClickRegister = event => {
    setLoading(true);

    let error = false;
    setPasswordError('');

    if (!(password.length > 7)) {
      setPasswordError('active');
      error = true;
    }

    if (window.grecaptcha && window.grecaptcha.getResponse() !== '') {
      setRecaptchaError('');
    } else {
      setRecaptchaError('active');
      error = true;
    }

    if (error) {
      setLoading(false);
      return;
    }

    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: 'https://api.analisedeacoes.com/account/register',
      data: {
        name,
        email,
        phone,
        password,
        patrimony,
        recaptcha: window.grecaptcha.getResponse(),
        lp: true
      }
    })
      .then(result => {
        setLoading(false);
        Bootstrap.showModal('#success-registered');
        Bootstrap.onModalClose('#success-registered', () => {
          if (result.data && result.data.token) {
            // let account = JWT.decode(result.data.token);
            Analytics.track('Mapa de Dividendos FIIs', 'Formulário', 'Cadastro efetuado');
            // if (account && account.email) {
              // if (Account.setAccount(account, result.data.token)) {
                window.location.href = `https://www.analisedeacoes.com/${origin ? '?utm_source=' + origin + '&utm_medium=mapadedividendos' : ''}`;
              // }
            // }
          }
        });
      })
      .catch(error => {
        setLoading(false);
        if (error.response.status === 400) {
          Bootstrap.showModal('#error-invalid-request');
        } else if (error.response.status === 409) {
          Bootstrap.showModal('#already-registered');
        } else {
          Bootstrap.showModal('#error');
        }
      });
  };


  const trackAnalytics = () => {
    $('#dividendsmap-success .breadcrumb .gotohome').on('click', () => Analytics.track('Mapa de Dividendos FIIs', 'Breadcrumb', 'Página inicial'));
  };

  useEffect(() => {
    setOrigin(Origin.get());

    if (location.location.state) {
      setName(location.location.state.name);
      setEmail(location.location.state.email);
      setPhone(location.location.state.phone);
      setPatrimony(location.location.state.patrimony);
      
      if (location.location.state.showForm) {
        setShowPasswordForm(true);
      }
    }
    trackAnalytics();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <Layout page="dividendsmap-success" name="mapadedividendos">
      <Seo
        title={`Mapa de Dividendos ${year}`}
        description="Saiba agora quando as ações costumam pagar dividendos, o mapa de dividendos do Análise de Ações é gratuito e informa em quais meses as empresas da bolsa pagam dividendos."
        image={'/images/helpers/cover-mapa-de-dividendos-analise-de-acoes-fiis.jpg'}
        url="/mapa-de-dividendos-fiis/sucesso/" />

      <section className={'dividendsmap'}>
        <div className={'container'}>
          <div className={'row'}>
            <div className={'col-xs-12'}>
              <h1 className={'title'}>Quase lá!</h1>
              <p className={'subtitle'}>Em alguns minutos você vai receber o Mapa de Dividendos no seu e-mail, caso demore mais de 15 minutos verifique a sua caixa de spam :)</p>
              { /* <a href="" className="button button-primary button-download">Baixar agora!</a> */ }
            </div>
          </div>

          {
            (() => {
              if (showPasswordForm) {
                return (
                  <div className={'row register'}>
                    <div className={'col-xs-12'}>
                      <h2>Agora crie uma senha para a sua conta.</h2>
                      <p>Criando sua senha você vai ter acesso completo a todas as nossas ferramentas gratuitas, como agenda de dividendos, comunidade, análises e muito mais.</p>

                      { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                      <label className={`error ${recaptchaError}`}>Você não validou o captcha.</label>

                      <div className={"input col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                        <input type="password" className={"form-control"} placeholder="Senha *" name="password" value={password} onChange={handleInputChange} maxLength={50} required />
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }
                        <label className={`error ${passwordError}`}>A senha precisa ter pelo menos 8 caracteres.</label>
                      </div>

                      <button type="button" className={"button button-primary"} onClick={handleClickRegister} disabled={loading}>{ loading ? 'Carregando' : 'Finalizar cadastro'}</button>
                    </div>

                    <div className={"col-lg-12"}>
                      <div className="g-recaptcha" data-sitekey={"6LfxBNoZAAAAAIMYVv94MeoQz6GueyaSBpcKJANJ"}></div>
                    </div>
                  </div>
                )
              } else {
                return (
                  <a href={`https://www.analisedeacoes.com/${origin ? '?utm_source=' + origin + '&utm_medium=mapadedividendos' : ''}`} className="button button-primary button-cantregister">Continuar</a>
                )
              }
            })()
          }
        </div>
      </section>

      <Modal page={{ id: 'dividendsmap-success', name: 'Mapa de Dividendos (Sucesso)'}} title="Cadastro com sucesso" id="success-registered" content={{ title: 'Você foi cadastrado com sucesso.', subtitle: 'Em breve você vai receber um e-mail com um link para validar a sua conta.' }} buttonClose={false} actionSecondary={{ label: 'Continuar' }} />
      <Modal page={{ id: 'dividendsmap-success', name: 'Mapa de Dividendos (Sucesso)'}} title="Erro na requisição" id="error-invalid-request" content={{ title: 'Não foi possível realizar o seu cadastro, verifique os dados informados e tente novamente.' }} buttonClose={true} actionSecondary={{ label: 'Tentar novamente' }} />
      <Modal page={{ id: 'dividendsmap-success', name: 'Mapa de Dividendos (Sucesso)'}} title="Já possui cadastro" id="already-registered" content={{ title: 'Você já possui uma conta cadastrada.' }} buttonClose={true} actionSecondary={{ label: 'Ir para login', action: goToLogin }} />
      <Modal page={{ id: 'dividendsmap-success', name: 'Mapa de Dividendos (Sucesso)'}} title="Erro não mapeado" id="error" content={{ title: 'Não foi possível realizar o seu cadastro no momento, tente novamente mais tarde.' }} buttonClose={false} actionSecondary={{ label: 'Fechar' }} />

      <Helmet>
        <script src={`https://www.google.com/recaptcha/api.js?r=${Math.random()}`} async defer></script>
      </Helmet>
    </Layout>
  );
};

export default DividendsMapSuccessPage;
